import {
  isDailySchedule,
  isDayOfMonthSchedule,
  isDayOfWeekInstanceSchedule,
  isDaysOfWeekSchedule,
  JobPayload,
} from '@rego-engage/engage-types';
import { format, parse } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { DateTime, DateTimeUnit, Interval } from 'luxon';

export const getClientTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const formatDate = (date?: string | Date | null, timeZone?: string) => {
  if (!date) {
    return '';
  }
  const transformedDate = convertTimeZone(date, timeZone);
  return new Date(transformedDate).toLocaleString('en-US', {
    timeZone: timeZone ?? 'UTC',
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  });
};

export const convertTimeZone = (
  datetimeStr: string | Date | null,
  timezone?: string,
): string => {
  if (!datetimeStr) {
    return '';
  }
  const utcDatetime = new Date(datetimeStr).toLocaleString('en-US', {
    timeZone: timezone ?? 'UTC',
  });
  const formattedDatetime = format(
    new Date(utcDatetime),
    'yyyy-MM-dd HH:mm:ss',
  );
  return formattedDatetime;
};

export const UTCTimeToTimezone = (
  hour?: number,
  minute?: number,
  timezone?: string,
) => {
  const iDate = new Date();
  iDate.setUTCHours(hour ?? 0);
  iDate.setUTCMinutes(minute ?? 0);
  const formattedDate = iDate.toLocaleTimeString('en-US', {
    timeZone: timezone ?? 'UTC',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
  });
  return formattedDate;
};

export const TimezoneToUTCTime = (
  hour?: number,
  minute?: number,
  timezone?: string,
) => {
  const iDate = new Date();
  iDate.setHours(hour ?? 0);
  iDate.setMinutes(minute ?? 0);
  const datetimeStr = format(iDate, 'yyyy-MM-dd HH:mm:ss');
  const utc = zonedTimeToUtc(datetimeStr, timezone ?? 'UTC');
  return `${utc.getUTCHours()}:${utc.getUTCMinutes()}`;
};

export const isoDate = (date?: string | Date | null, timeZone = 'UTC') => {
  const dateObj = date ? new Date(date) : new Date();

  // Format the date in the specified time zone
  // Note: Canadian locale formats as 'yyyy-MM-dd'
  const formattedDate = dateObj.toLocaleString('en-CA', {
    timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  // Return the date part only
  return formattedDate.split(' ')[0];
};

type TimeZoneNameFormat =
  | 'long'
  | 'short'
  | 'shortOffset'
  | 'longOffset'
  | 'shortGeneric'
  | 'longGeneric';

interface GetTimeZoneOptions {
  format: TimeZoneNameFormat;
  timeZone?: string;
  date?: string | number | Date;
  locales?: string | string[];
}

export const getTimeZone = ({
  timeZone,
  format,
  date,
  locales,
}: GetTimeZoneOptions) => {
  const formatter = new Intl.DateTimeFormat(locales, {
    timeZone,
    timeZoneName: format,
  });

  const dateObj = date ? new Date(date) : Date.now();
  const dateParts = formatter.formatToParts(dateObj);
  const timeZoneName = dateParts.find(
    formatted => formatted.type === 'timeZoneName',
  )?.value;

  return timeZoneName ?? '';
};

// Timezones that have an offset of zero
const zeroOffsetTzs = ['UTC', 'Etc/UTC', 'GMT', 'Etc/GMT'];

/**
 * Calculates the timezone offset from UTC for a given timezone.
 * Optionally, provide a specific date for which to calculate the offset.
 *
 * @param timezone - The IANA timezone string, e.g., "America/New_York".
 * @param date - (optional) The date for which to calculate the offset. Defaults to the current date and time if not provided.
 * @returns The timezone offset in iso format "+HH:MM" or "-HH:MM".
 *
 * @example
 * ```ts
 * // March 12, 2023 at 06:00 UTC (01:00 New York time).
 * const offset = getTimezoneOffset('America/New_York', '2023-03-12T06:00:00Z');
 * console.log(offset);
 * // Output: "-04:00" - DST would not start until 2AM New York time.
 *
 * // Determine the timezone offset for New York at this moment.
 * const offset2 = getTimezoneOffset('America/New_York');
 * console.log(offset2);
 * // Output: "-04:00" or "-05:00" depending on the current date and time in New York
 * ```
 */
export const getTimezoneOffset = (
  timezone: string,
  date?: string | number | Date,
) => {
  if (zeroOffsetTzs.includes(timezone)) {
    return '+00:00';
  }

  const dateObj = date ? new Date(date) : new Date();

  // Format the UTC time for the specified timezone
  const formattedTimeInTimezone = new Intl.DateTimeFormat('en-US', {
    timeZone: timezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  }).format(dateObj);

  // Parse the formatted time as UTC
  const timezoneTime = new Date(`${formattedTimeInTimezone} UTC`);

  // Calculate the difference in milliseconds
  const offsetMillis = timezoneTime.getTime() - dateObj.getTime();

  // Convert milliseconds to total minutes and round it to avoid floating point issues
  const totalOffsetMinutes = Math.round(offsetMillis / 60000);

  // Separate total minutes into hours and minutes
  let offsetHours = Math.floor(Math.abs(totalOffsetMinutes) / 60);
  let offsetMinutes = Math.abs(totalOffsetMinutes % 60);

  // Adjust for edge case where minutes round to 60
  if (offsetMinutes === 60) {
    offsetHours += 1;
    offsetMinutes = 0;
  }

  // Determine the sign of the offset
  const sign = totalOffsetMinutes >= 0 ? '+' : '-';

  // Format the offset string
  const formattedOffset = `${sign}${offsetHours
    .toString()
    .padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;

  return formattedOffset;
};

/**
 * Returns an object with the date and time components of the current date in the specified timezone,
 * or of a given date in the specified timezone if provided.
 *
 * @param params - The parameters object.
 * @param params.date - The date for which to calculate the components. Defaults to the current date and time if not provided.
 * @param params.timezone - The IANA timezone string, e.g., "America/New_York". Defaults to "UTC" if not provided.
 * @returns An object with the date and time components.
 *
 * @example
 * ```ts
 * // Get the components for the current date and time in UTC
 * const components = isoDateTimeComponents();
 * console.log(components);
 * // Output: { isoDate: '2023-06-14', time: '12:00:00', ms: '123', offset: '+00:00' }
 *
 * // Get the components for a specific date in UTC
 * const components2 = isoDateTimeComponents({ date: '2023-03-12T06:00:00Z' });
 * console.log(components2);
 * // Output: { isoDate: '2023-03-12', time: '06:00:00', ms: '000', offset: '+00:00' }
 *
 * // Get the components for the current date and time in a specific timezone
 * const components3 = isoDateTimeComponents({ timezone: 'America/New_York' });
 * console.log(components3);
 * // Output: { isoDate: '2023-06-14', time: '08:00:00', ms: '123', offset: '-04:00' }
 *
 * // Get the components for a specific date in a specific timezone
 * const components4 = isoDateTimeComponents({ date: '2023-03-12T06:00:00Z', timezone: 'America/New_York' });
 * console.log(components4);
 * // Output: { isoDate: '2023-03-12', time: '01:00:00', ms: '000', offset: '-05:00' }
 * ```
 */
export const isoDateTimeComponents = ({
  date,
  timezone = 'UTC',
}: {
  date?: Date | number | string;
  timezone?: string;
} = {}): { isoDate: string; time: string; ms: string; offset: string } => {
  const dateObj = date ? new Date(date) : new Date();

  const isoDate = new Intl.DateTimeFormat('en-CA', {
    timeZone: timezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(dateObj);

  const time = new Intl.DateTimeFormat('en-US', {
    timeZone: timezone,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  }).format(dateObj);

  const ms = dateObj.getMilliseconds().toString().padStart(3, '0');

  const offset = getTimezoneOffset(timezone, date);

  return { isoDate, time, ms, offset };
};
/**
 * Returns a template literal tag function for formatting date and time components
 * of a given date in the specified timezone, or of the current date in the specified timezone if no date is provided.
 *
 * @param {Object} params - The parameters object.
 * @param {Date | number | string} [params.date] - The date for which to calculate the components. Defaults to the current date and time if not provided.
 * @param {string} [params.timezone='UTC'] - The IANA timezone string, e.g., "America/New_York". Defaults to "UTC" if not provided.
 * @returns {Function} A template literal tag function for formatting date and time components.
 *
 * @example
 * ```ts
 *  // One-liner usage
 * const result = isoDateTimeFormat()`Date: ${'isoDate'}, Time: ${'time'}, Offset: ${'offset'}`;
 * console.log(result);
 * // Output: "Date: 2023-06-14, Time: 12:00:00, Offset: +00:00"
 *
 * // Friendly format for logging
 * const logFormat = isoDateTimeFormat({ timezone: 'America/Los_Angeles' });
 * const logMessage = logFormat`[${'isoDate'} ${'time'} ${'offset'}] - User logged in`;
 * console.log(logMessage);
 * // Output: "[2023-06-14 08:00:00 -07:00] - User logged in"
 *
 * // ISO 8601 timestamp for API request
 * const apiFormat = isoDateTimeFormat();
 * const apiTimestamp = apiFormat`${'isoDate'}T${'time'}.${'ms'}${'offset'}`;
 * console.log(apiTimestamp);
 * // Output: "2023-06-14T12:00:00.123+00:00"
 *
 * // Display date and time in a user interface
 * const uiFormat = isoDateTimeFormat({ date: '2023-06-14T12:00:00Z', timezone: 'Europe/London'});
 * const uiMessage = uiFormat`${'isoDate'} ${'time'}.${'ms'}`;
 * console.log(uiMessage);
 * // Output: "Date: 2023-06-14, Time: 13:00:00, Timezone: +01:00"
 * ```
 */
export const isoDateTimeFormat =
  ({
    date,
    timezone = 'UTC',
  }: {
    date?: Date | number | string;
    timezone?: string;
  } = {}) =>
  (strings: TemplateStringsArray, ...keys: string[]) => {
    const values = isoDateTimeComponents({ date, timezone });

    return strings.reduce((result, part, index) => {
      const key = keys[index] as keyof typeof values;
      return result + part + (key ? values[key] : '');
    }, '');
  };

/**
 * Returns an ISO 8601 formatted date and time string for the current date and time in the specified timezone,
 * or for a given date in the specified timezone if provided.
 * The format is "YYYY-MM-DDTHH:mm:ss.SSS±HH:MM".
 *
 * @param params - The parameters object.
 * @param params.date - The date for which to calculate the components. Defaults to the current date and time if not provided.
 * @param params.timezone - The IANA timezone string, e.g., "America/New_York". Defaults to "UTC" if not provided.
 * @returns An ISO 8601 formatted date and time string.
 *
 * @example
 * ```ts
 * // Get the ISO 8601 formatted date and time for a specific date in a specific timezone
 * const isoDateTime = isoDateTime({ date: '2023-03-12T06:00:00Z', timezone: 'America/New_York' });
 * console.log(isoDateTime);
 * // Output: "2023-03-12T01:00:00.000-05:00"
 * ```
 */
export const isoDateTime = ({
  date,
  timezone = 'UTC',
}: {
  date?: Date | number | string;
  timezone?: string;
} = {}) =>
  isoDateTimeFormat({
    date,
    timezone,
  })` ${'isoDate'}T${'time'}.${'ms'}${'offset'}`;

/**
 * Returns a formatted string representing the date and time with milliseconds
 * for a given date in the specified timezone, or of the current date in the specified timezone if no date is provided.
 *
 * @param params - The parameters object.
 * @param params.date - The date for which to calculate the components. Defaults to the current date and time if not provided.
 * @param params.timezone - The IANA timezone string, e.g., "America/New_York". Defaults to "UTC" if not provided.
 * @returns A formatted string representing the date and time with milliseconds.
 *
 * @example
 * ```ts
 * // Get the formatted date and time with milliseconds for a specific date in a specific timezone
 * const dateTime = uiDateTime({ date: '2023-03-12T06:00:00Z', timezone: 'America/New_York' });
 * console.log(dateTime);
 * // Output: "2023-03-12 01:00:00.000"
 * ```
 */
export const uiDateTime = ({
  date,
  timezone = 'UTC',
}: {
  date?: Date | number | string;
  timezone?: string;
} = {}) =>
  isoDateTimeFormat({
    date,
    timezone,
  })`${'isoDate'} ${'time'}.${'ms'}`;

export const dateToIsoTz = (date: string, timezone: string) => {
  // Parse the input date string in the specified timezone
  const zonedInputDate = DateTime.fromISO(date, { zone: timezone });

  // Get the current date and time in the specified timezone
  const nowInTimeZone = DateTime.now().setZone(timezone);

  if (!zonedInputDate.isValid || !nowInTimeZone.isValid) {
    throw new Error('Invalid timezone');
  }

  if (zonedInputDate.hasSame(nowInTimeZone, 'day')) {
    // If it is today, return the current date and time in the specified timezone
    return nowInTimeZone.toISO();
  } else {
    // If it is in the future, return the provided date at midnight in the specified timezone
    return zonedInputDate.toISO();
  }
};

const dateInTz = (timezone = 'UTC', date?: string): DateTime<true> => {
  const dateTime = date
    ? DateTime.fromISO(date, { zone: timezone })
    : DateTime.now().setZone(timezone);

  if (!dateTime.isValid) {
    throw new Error('Invalid timezone');
  }

  return dateTime;
};

export const isoDateInTz = (timezone = 'UTC', date?: string): string => {
  return dateInTz(timezone, date).toISODate();
};

export const isoDateStartOfInTz = (
  timezone = 'UTC',
  startOf: DateTimeUnit,
  date?: string,
): string => {
  return dateInTz(timezone, date).startOf(startOf).toISO();
};

export const isoDateEndOfInTz = (
  timezone = 'UTC',
  endOf: DateTimeUnit,
  date?: string,
): string => {
  return dateInTz(timezone, date).endOf(endOf).toISO();
};

const dateTomorrowInTz = (timezone = 'UTC'): DateTime<true> => {
  const now = DateTime.now().setZone(timezone);

  if (!now.isValid) {
    throw new Error('Invalid timezone');
  }

  return now.plus({ days: 1 }); // Add one day
};

export const isoDateTomorrowInTz = (timezone = 'UTC'): string => {
  return dateTomorrowInTz(timezone).toISODate(); // YYYY-MM-DD
};

export const isoDateTomorrowEndOfInTz = (
  timezone = 'UTC',
  endOf: DateTimeUnit,
): string => {
  return dateTomorrowInTz(timezone).endOf(endOf).toISO(); // YYYY-MM-DD
};

export const getDateRangeFromText = (text: string) => {
  const today = DateTime.now();
  let interval: Interval;

  switch (text.toLowerCase()) {
    case 'last 12 months':
      interval = Interval.fromDateTimes(
        today.minus({ months: 11 }).startOf('month'),
        today.endOf('month'),
      );
      break;
    case 'last 6 months':
      interval = Interval.fromDateTimes(
        today.minus({ months: 5 }).startOf('month'),
        today.endOf('month'),
      );
      break;
    case 'last 3 months':
      interval = Interval.fromDateTimes(
        today.minus({ months: 2 }).startOf('month'),
        today.endOf('month'),
      );
      break;
    case 'this month':
      interval = Interval.fromDateTimes(
        today.startOf('month'),
        today.endOf('month'),
      );
      break;
    case 'last month':
      interval = Interval.fromDateTimes(
        today.minus({ months: 1 }).startOf('month'),
        today.minus({ months: 1 }).endOf('month'),
      );
      break;
    case 'this year':
      interval = Interval.fromDateTimes(
        today.startOf('year'),
        today.endOf('year'),
      );
      break;
    case 'last year':
      interval = Interval.fromDateTimes(
        today.minus({ years: 1 }).startOf('year'),
        today.minus({ years: 1 }).endOf('year'),
      );
      break;
    case 'last week':
      interval = Interval.fromDateTimes(
        today.minus({ weeks: 1 }).startOf('week'),
        today.minus({ weeks: 1 }).endOf('week'),
      );
      break;
    case 'this week':
      interval = Interval.fromDateTimes(
        today.startOf('week'),
        today.endOf('week'),
      );
      break;
    case 'this quarter':
      interval = Interval.fromDateTimes(
        today.startOf('quarter'),
        today.endOf('quarter'),
      );
      break;
    case 'last quarter':
      interval = Interval.fromDateTimes(
        today.minus({ quarters: 1 }).startOf('quarter'),
        today.minus({ quarters: 1 }).endOf('quarter'),
      );
      break;
    case 'last 3 days':
      interval = Interval.fromDateTimes(today.minus({ days: 3 }), today);
      break;
    case 'last 7 days':
      interval = Interval.fromDateTimes(today.minus({ days: 7 }), today);
      break;
    default:
      interval = Interval.fromDateTimes(today, today);
      break;
  }

  if (
    !interval.isValid ||
    interval.isEmpty() ||
    interval.start === null ||
    interval.end === null
  ) {
    throw new Error('Invalid date range');
  }

  return {
    minDate: interval.start.toISODate(),
    maxDate: interval.end.toISODate(),
  };
};

export const convertFrom24HourTimeTo12HourTime = (time: string) => {
  const toFormatTime = parse(time, 'HH:mm', new Date());
  return format(toFormatTime, 'hh:mm a');
};

export const timeRepeat = (job: JobPayload): string => {
  const schedule = job.scheduleParams.value;
  let repeatText = '';
  let time = '';

  if (
    isDailySchedule(schedule) ||
    isDaysOfWeekSchedule(schedule) ||
    isDayOfMonthSchedule(schedule) ||
    isDayOfWeekInstanceSchedule(schedule)
  ) {
    time = convertFrom24HourTimeTo12HourTime(
      `${schedule.hours[0]}:${schedule.minutes[0]}`,
    );
  }

  if (isDailySchedule(schedule)) {
    repeatText += 'Daily at ';
    repeatText += time;
    repeatText += ` every ${schedule.dayOfMonth.increment} day(s).`;
  } else if (isDaysOfWeekSchedule(schedule)) {
    repeatText += 'Weekly at ';
    repeatText += time;
    const dayOfWeekText = schedule.dayOfWeek
      .map(dayOfWeekMap => {
        if (dayOfWeekMap === 1) {
          return 'Sunday';
        } else if (dayOfWeekMap === 2) {
          return 'Monday';
        } else if (dayOfWeekMap === 3) {
          return 'Tuesday';
        } else if (dayOfWeekMap === 4) {
          return 'Wednesday';
        } else if (dayOfWeekMap === 5) {
          return 'Thursday';
        } else if (dayOfWeekMap === 6) {
          return 'Friday';
        } else if (dayOfWeekMap === 7) {
          return 'Saturday';
        }
      })
      .join(', ');

    repeatText += ` every ${dayOfWeekText}.`;
  } else if (isDayOfMonthSchedule(schedule)) {
    repeatText += 'Monthly at ';
    repeatText += time;
    repeatText += ` every ${schedule.month.increment} month(s)`;

    if (schedule.dayOfMonth) {
      repeatText += ` on ${schedule.dayOfMonth[0]} of every month.`;
    }
  } else if (isDayOfWeekInstanceSchedule(schedule)) {
    repeatText += 'Monthly at ';
    repeatText += time;
    repeatText += ` every ${schedule.month.increment} month(s)`;

    let dayOfWeekText = '';
    if (schedule.dayOfWeek[0] === 1) {
      dayOfWeekText += 'Sunday';
    } else if (schedule.dayOfWeek[0] === 2) {
      dayOfWeekText += 'Monday';
    } else if (schedule.dayOfWeek[0] === 3) {
      dayOfWeekText += 'Tuesday';
    } else if (schedule.dayOfWeek[0] === 4) {
      dayOfWeekText += 'Wednesday';
    } else if (schedule.dayOfWeek[0] === 5) {
      dayOfWeekText += 'Thursday';
    } else if (schedule.dayOfWeek[0] === 6) {
      dayOfWeekText += 'Friday';
    } else if (schedule.dayOfWeek[0] === 7) {
      dayOfWeekText += 'Saturday';
    }

    if (schedule.dayOfWeekInstance) {
      let dayOfWeekInstanceText = '';
      if (schedule.dayOfWeekInstance === 1) {
        dayOfWeekInstanceText += 'First';
      } else if (schedule.dayOfWeekInstance === 2) {
        dayOfWeekInstanceText += 'Second';
      } else if (schedule.dayOfWeekInstance === 3) {
        dayOfWeekInstanceText += 'Third';
      } else if (schedule.dayOfWeekInstance === 4) {
        dayOfWeekInstanceText += 'Fourth';
      }
      repeatText += ` the ${dayOfWeekInstanceText} ${dayOfWeekText}.`;
    } else if (schedule.lastInstanceOfDayOfWeek) {
      repeatText += ` the Last ${dayOfWeekText}.`;
    }
  }

  return repeatText;
};

export const getFormattedDateWithTimezone = (
  date: string,
  isEndOfDay = false,
) => {
  let dateTime = DateTime.fromISO(date).setZone('local');

  if (isEndOfDay) {
    // Set to the end of the day: 23:59:59
    dateTime = dateTime.endOf('day');
  } else {
    // Set to the start of the day: 00:00:00
    dateTime = dateTime.startOf('day');
  }

  const formattedDateTimeString = dateTime.toISO() ?? '';
  return formattedDateTimeString;
};
