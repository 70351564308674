import { createAsyncThunk } from '@reduxjs/toolkit';

import CognitoService from '../../services/cognito';
import { AuthState } from './types';

const {
  auth: cognitoAuth,
  logout: cognitoLogout,
  forgotPassword: cognitoForgotPassword,
  confirmPassword: cognitoConfirmPassword,
  refreshSession: cognitoRefreshSession,
} = CognitoService;

export type AuthenticationData = {
  email: string;
  password: string;
  challengeConfirmation?: string;
};

export const authenticate = createAsyncThunk<
  AuthState,
  AuthenticationData,
  { rejectValue: AuthState }
>('auth/login', async (data, thunkAPI) => {
  try {
    const { isSignedIn, nextStep, user, ...rest } = await cognitoAuth(
      data.email,
      data.password,
      data.challengeConfirmation,
    );

    const isAuth = isSignedIn;

    const authState: AuthState = {
      user: {
        email: user?.username || data.email,
        id: user?.userId,
      },
      password: !isAuth ? data.password : undefined,
      loading: false,
      isAuth,
      isNewPasswordRequired:
        nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
      isMFARequired: nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_SMS_CODE',
      isPasswordResetRequired: nextStep?.signInStep === 'RESET_PASSWORD',
      ...rest,
    };

    return authState;
  } catch (errBase) {
    const err = errBase as Error;
    const errorState: AuthState = {
      loading: false,
      error: `${err.message}`,
      isAuth: false,
    };

    return thunkAPI.rejectWithValue(errorState);
  }
});

export const signup = createAsyncThunk<
  AuthState,
  { email: string; password: string },
  { rejectValue: AuthState }
>('auth/signup', async (data, thunkAPI) => {
  try {
    const response = await fetch('/api/auth/signup', {
      method: 'POST',
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const { user, ...rest } = (await response.json()) as AuthState;
      const authState: AuthState = {
        user,
        ...rest,
        loading: false,
        isAuth: true,
      };
      return authState;
    } else {
      const error = await response.text();
      throw new Error(error);
    }
  } catch (errBase) {
    const err = errBase as Error;
    const errorState: AuthState = {
      loading: false,
      error: `${err.message}`,
      isAuth: false,
    };
    return thunkAPI.rejectWithValue(errorState);
  }
});

export const logout = createAsyncThunk<
  AuthState,
  unknown,
  { rejectValue: AuthState }
>('auth/logout', async (_, thunkAPI) => {
  try {
    await cognitoLogout();
    const authState: AuthState = {
      loading: false,
      isAuth: false,
    };
    return authState;
  } catch (errBase) {
    const err = errBase as Error;
    const errorState: AuthState = {
      loading: false,
      error: `${err.message}`,
      isAuth: false,
    };
    return thunkAPI.rejectWithValue(errorState);
  }
});

export const refreshSession = createAsyncThunk<
  AuthState,
  { quietMode?: boolean },
  { rejectValue: AuthState }
>('auth/refresh', async (_, thunkAPI) => {
  try {
    const { user, ...rest } = await cognitoRefreshSession();
    const authState: AuthState = {
      user: {
        email: user?.username,
        id: user?.userId,
      },
      ...rest,
      loading: false,
      isAuth: true,
    };
    return authState;
  } catch (_err) {
    const errorState: AuthState = {
      loading: false,
      isAuth: false,
    };
    return thunkAPI.rejectWithValue(errorState);
  }
});

export const forgotPasswordRequest = createAsyncThunk<
  AuthState,
  { email: string },
  { rejectValue: AuthState }
>('auth/forgotPasswordRequest', async (data, thunkAPI) => {
  try {
    const response = await cognitoForgotPassword(data.email);
    const authState: AuthState = {
      loading: false,
      isAuth: false,
      user: response,
    };
    return authState;
  } catch (errBase) {
    const errorState: AuthState = {
      loading: false,
      isAuth: false,
      error: (errBase as Error).message,
    };
    return thunkAPI.rejectWithValue(errorState);
  }
});

export const confirmPassword = createAsyncThunk<
  AuthState,
  { email: string; code: string; newPassword: string },
  { rejectValue: AuthState }
>('auth/confirmPassword', async (data, thunkAPI) => {
  try {
    await cognitoConfirmPassword(data.email, data.code, data.newPassword);

    const loginResponse = await cognitoAuth(data.email, data.newPassword);
    const authState: AuthState = {
      ...loginResponse,
      loading: false,
      isAuth: loginResponse.isSignedIn,
      user: loginResponse.user && {
        email: loginResponse.user.username,
        id: loginResponse.user.userId,
      },
    };

    return authState;
  } catch (errBase) {
    const errorState: AuthState = {
      loading: false,
      isAuth: false,
      error: (errBase as Error).message,
    };
    return thunkAPI.rejectWithValue(errorState);
  }
});
